import { FetchConfig }        from 'aurelia-auth';
import { inject }             from 'aurelia-framework';
import { Router }             from 'aurelia-router';
import { RouterConfig }       from 'resources/configs/router-config';
import { AuthenticatedUser }  from 'resources/services/authenticated-user';
import { LaravelEchoService } from 'resources/services/laravel-echo-service';
import { LocalStorage }       from 'resources/services/local-storage';

@inject(Router, FetchConfig, RouterConfig, AuthenticatedUser, LocalStorage, LaravelEchoService)
export class App {

    /**
     * Constructor
     *
     * @param router
     * @param fetchConfig
     * @param routerConfig
     * @param authenticatedUser
     * @param localStorage
     * @param laravelEchoService
     */
    constructor(router, fetchConfig, routerConfig, authenticatedUser, localStorage, laravelEchoService) {
        this.router             = router;
        this.fetchConfig        = fetchConfig;
        this.routerConfig       = routerConfig;
        this.authenticatedUser  = authenticatedUser;
        this.localStorage       = localStorage;
        this.laravelEchoService = laravelEchoService;
    }

    /**
     * Handles activate event
     */
    activate() {
        this.fetchConfig.configure();
        this.routerConfig.configure();

        return this.authenticatedUser.fetchAuthenticatedUser().then(() => this.laravelEchoService.instantiate());
    }

    /**
     * Runs after view is attached
     */
    attached() {
        document.body.className = 'navbar-top';

        $.ajaxSetup({
            headers: {
                'Authorization': 'Bearer ' + this.localStorage.authToken(),
            },
        });
    }

}
